export type Option = { text: string; value: string } & Record<string, string>;

type Props = {
  options: Option[];
  active: Option;
  setActive: (value: Option) => void;
};
export const Tumbler = (props: Props) => {
  const { options, active, setActive } = props;

  return (
    <div className="flex gap-2 rounded-xl bg-gray-second p-1">
      {options.map((opt) => (
        <button
          key={opt.value}
          onClick={() => setActive(opt)}
          className={`flex h-8 w-full items-center justify-center rounded-lg ${active.value === opt.value ? 'bg-white font-medium text-text' : 'text-second'}`}
        >
          {opt.text}
        </button>
      ))}
    </div>
  );
};
