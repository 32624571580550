import { ReactComponent as GrateIcon } from '../../icons/grate-icon.svg';
import { ReactComponent as Arrow } from '../../icons/arrow.svg';
import { SimpleLink } from '../../components/atoms/simple-link';

export const NotebookWishes = () => {
  return (
    <div className="flex h-[calc(100vh_-_96px)] flex-col justify-center gap-10">
      <div className="flex flex-col items-center gap-4">
        <GrateIcon />
        <h1>Дневник желаний</h1>
        <p className="text-center">усиливает тягу к жизни, помогая сфокусироваться на достижении своих желаний.</p>
      </div>
      <div className="flex w-full flex-col gap-2">
        <SimpleLink to="/notebooks/wishes/list" className="contents">
          <button className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-blue font-medium text-white">
            Записать желание
            <Arrow />
          </button>
        </SimpleLink>

        <SimpleLink to="/notebooks" className="contents">
          <button className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-gray-second font-medium text-black">
            К другим дневникам
          </button>
        </SimpleLink>
      </div>
    </div>
  );
};
