import { Link, LinkProps, useResolvedPath } from 'react-router-dom';
import { handleClickNav } from '../../../utils/routes';

export const SimpleLink = ({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to);
  const { pathname } = window.location;

  return (
    <Link to={to} onClick={handleClickNav(pathname, resolved.pathname)} {...props}>
      {children}
    </Link>
  );
};
