import { useState } from 'react';
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg';

export const Exercise = (props: {
  name: string;
  content: string;
  image: string;
  background: string;
  color?: string;
}) => {
  const { name, image, background, content, color } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <div onClick={() => setOpen(true)} className="h-[166px] w-full rounded-xl" style={{ background }}>
        <div
          style={{
            backgroundImage: `url(/images/${image})`,
            color,
          }}
          className="flex h-[166px] w-full items-end rounded-xl bg-cover p-[10px] text-sm font-medium leading-[120%]"
        >
          {name}
        </div>
      </div>

      <div
        className="fixed left-0 top-0 flex h-full w-full flex-col duration-150 ease-in"
        style={{ background, top: open ? '0' : '100%', opacity: open ? '1' : '0' }}
      >
        <div
          style={{
            backgroundImage: `url(/images/${image})`,
          }}
          className="absolute -top-10 flex h-1/3 min-h-[188px] w-full bg-cover"
        />

        <button
          className="absolute right-4 top-[148px] z-10 flex h-6 w-6 items-center justify-center rounded-full bg-bg p-[2px]"
          onClick={() => setOpen(false)}
        >
          <CrossIcon />
        </button>
        <div className="h-32 flex-none" />
        <div className="relative flex h-full flex-col gap-5 overflow-auto rounded-t-[32px] bg-bg p-6 pb-20">
          <h1>{name}</h1>
          <pre className="whitespace-break-spaces leading-[22.4px]">{content}</pre>
        </div>
      </div>
    </>
  );
};
