import { SimpleLink } from '../../components/atoms/simple-link';
import { ReactComponent as BookIcon } from 'icons/book.svg';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { ReactComponent as CrossIcon } from 'icons/cross.svg';
import { useState } from 'react';

export const NotebookFearCreate = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>('');
  // todo add normal db
  const [allItems, setAllItems] = useState<string[]>([]);

  const handleSave = (grate: string) => {
    setAllItems((all) => [...all, grate]);
  };

  const handleClickSave = () => {
    handleSave(value);
    setValue('');
    setOpen(false);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-3 text-xs">
        <SimpleLink className="text-blue" to="/notebooks">
          /Дневники
        </SimpleLink>
        <span className="text-second">/Дневник страхов</span>
      </div>

      <h1>Дневник страхов</h1>

      {allItems.length > 0 ? null : (
        <>
          <div className="mx-auto my-[calc(50%_-_70px)] flex w-[200px] flex-col items-center gap-2 text-second">
            <BookIcon />
            <p className="text-center">Нажми на +, чтобы добавить запись</p>
          </div>
        </>
      )}

      <button
        onClick={() => setOpen(true)}
        className="fixed bottom-[88px] right-4 flex h-12 w-12 items-center justify-center rounded-full bg-blue shadow-[8px_11px_20px_0px_#2C4EFE33]"
      >
        <PlusIcon />
      </button>

      <div
        className="fixed left-0 top-0 flex h-full w-full flex-col duration-150 ease-in"
        style={{ top: open ? '0' : '100%', opacity: open ? '1' : '0' }}
      >
        <div className="absolute -top-10 flex h-1/3 min-h-[32px] w-full bg-[#bdbdbd]" />

        <button
          className="absolute right-4 top-[56px] z-10 flex h-6 w-6 items-center justify-center rounded-full bg-bg p-[2px]"
          onClick={() => setOpen(false)}
        >
          <CrossIcon />
        </button>
        <div className="h-8 flex-none" />
        <div className="relative flex h-full flex-col gap-6 overflow-auto rounded-t-[32px] bg-bg p-6 pb-20">
          <h1>Опиши свой страх</h1>

          <textarea
            value={value}
            onChange={({ target: { value } }) => setValue(value)}
            placeholder="Я боюсь, что..."
            className="min-h-[200px] rounded-xl bg-gray-second p-[10px]"
          />

          <div className="mt-auto flex w-full flex-col gap-2">
            <button
              onClick={handleClickSave}
              className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-blue font-medium text-white"
            >
              Сохранить
            </button>

            <SimpleLink to="/notebooks" className="contents">
              <button className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-gray-second font-medium text-black">
                Назад
              </button>
            </SimpleLink>
          </div>
        </div>
      </div>
    </div>
  );
};
