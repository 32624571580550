export const exercises = [
  {
    name: 'Противострессовое дыхание',
    content: `Медленно выполняйте глубокий вдох через нос. 
На пике вдоха на мгновение задержите дыхание, после чего сделайте выдох как можно медленнее. 

Это успокаивающее дыхание. Постарайтесь представить себе, что с каждым глубоким вдохом и продолжительным выдохом вы частично избавляетесь от стрессового напряжения.`,
    image: 'breathe/1.svg',
    background: '#647DFE',
    color: '#fff',
  },
  {
    name: 'Дышать и успокаиваться',
    content: `Сконцентрируйтесь на дыхании. Дышите глубоко, задержите воздух в легких на пару секунд, а затем выдохните. С выдохом должно уйти и ощущение скованности в теле. Для закрепления результата повторите цикл вдохов и выдохов примерно 3-5 раз. При этом нужно постараться максимально расслабиться, чуть поднять подбородок и слегка отклониться назад. Сидя на стуле, попытайтесь при очередном вдохе потянуть на себя края сиденья (словно вам нужно поднять стул вместе с собой). Затем задержите дыхание на мгновенье и медленно, спокойно делайте выдох через нос.`,
    image: 'breathe/2.svg',
    background: '#F4F4F7',
  },
  {
    name: '«Квадратное дыхание»',
    content: `Найдите в пространстве любой квадратный предмет: раму окна, картину, книгу. Зафиксируйте взгляд на верхнем левом углу и сделайте медленный вдох, мысленно считая до четырех, переведите взгляд на верхний правый угол и также на четыре счета задержите дыхание. Двигайтесь взглядом по сторонам квадрата, чередуя вдох, задержку дыхания, выдох. Голова при этом остается неподвижной, мышцы расслаблены.`,
    image: 'breathe/3.svg',
    background: '#F4F4F7',
  },
  {
    name: 'Глубокое дыхание',
    content: `Дышите по схеме 4-4-6:

- вдохните медленно, через нос, в течение четырех секунд,
- задержите дыхание на четыре секунды,
- выдохните медленно, через рот, в течение шести секунд. Лайфхак: представьте, что во рту вы держите трубочку для напитка — это поможет выдыхать медленнее.

Повторяйте цикл дыхания в течение хотя бы двух минут и постепенно увеличивайте время до 10 минут.

- Частая ошибка в этом упражнении — слишком быстрое дыхание. От этого может казаться, что техника не работает, и тревога не уходит. Отсчитывайте секунды очень медленно и подстраивайте дыхание под счет.
- Считайте вдохи и выдохи, чтобы переключить внимание с тревожных мыслей. Если заметили, что мысли увлекли вас, просто вернитесь к счету и продолжайте дышать.
- Дышите в удобном вам ритме. Если чувствуете, что можете вдыхать дольше четырех секунд, вдыхайте на шесть; если для вас это слишком долго, сократите до двух секунд.`,
    image: 'breathe/4.svg',
    background: '#DFB3FE',
    color: '#fff',
  },
  {
    name: 'Упражнение «Грейпфрут»',
    content: `Схема: представьте, что у вас внутри, в области пупка, находится шар размером с грейпфрут. «Надуйте» его с помощью дыхания. Медленно вдыхайте носом, одновременно надувая «грейпфрут» животе. Затем так же неспешно выдыхайте через рот. Повторяем 10-20 раз.`,
    image: 'breathe/5.svg',
    background: '#FF995F',
  },
  {
    name: 'Упражнение «Ходим и дышим»',
    content: `Схема: два шага – вдох, два шага – выдох.

Постепенно увеличивайте продолжительность выдоха: два шага – вдох, три шага – выдох и так далее.`,
    image: 'breathe/6.svg',
    background: '#F4F4F7',
  },
  //   {
  //     name: 'Без названия 1',
  //     content: `1. Положи ладонь на живот.

  // 2. Следи, чтобы ладонь двигалась при вдохе и выдохе.

  // 3. Медленно дыши 45 секунд.`,
  //     image: 'breathe/6.svg',
  //     background: '#F4F4F7',
  //   },
  //   {
  //     name: 'Без названия 2',
  //     content: `1. Быстро и глубоко вдохни через нос. Наполни легкие как можно больше.

  // 2. Не выдыхая, вдохни еще немного воздуха через нос.

  // 3. Теперь очень медленно выдохни через рот. Выдох должен быть длиннее, чем оба вдоха.

  // Медленно посчитай от 30 до 1 про себя.`,
  //     image: 'breathe/6.svg',
  //     background: '#F4F4F7',
  //   },
];
