import { supabase } from 'lib/supabase';
import useSWR from 'swr';
import { SimpleLink } from '../../components/atoms/simple-link';

export const Notebooks = () => {
  const { data: allWishes = [] } = useSWR<any[]>('wishes', async () => {
    const tg_id = localStorage.getItem('tg_id');
    if (!tg_id) {
      throw new Error('User not found');
    }
    const { data } = await supabase.from('note_wishes').select('*').eq('tg_id', Number(tg_id));
    const result = data || [];
    return result;
  });

  const { data: allGrates = [] } = useSWR<any[]>('grates', async () => {
    const tg_id = localStorage.getItem('tg_id');
    if (!tg_id) {
      throw new Error('User not found');
    }
    const { data } = await supabase.from('note_grates').select('*').eq('tg_id', Number(tg_id));
    const result = data || [];
    return result;
  });

  const { data: allFears = [] } = useSWR<any[]>('fears', async () => {
    const tg_id = localStorage.getItem('tg_id');
    if (!tg_id) {
      throw new Error('User not found');
    }
    const { data } = await supabase.from('note_fears').select('*').eq('tg_id', Number(tg_id));
    const result = data || [];
    return result;
  });

  return (
    <>
      <h1>Дневники</h1>
      <p className="mt-1">
        Используя дневники, ты можешь запечатлеть текущий жизненный процесс, события, идеи и переживания. Это письмо "из
        сейчас о сейчас". Здесь ты можешь выговориться и понять себя.
      </p>
      <div className="mt-3 flex flex-col gap-3">
        <SimpleLink
          to={allWishes.length > 0 ? 'wishes/list' : 'wishes'}
          style={{ backgroundImage: `url('images/notebooks/1.svg')` }}
          className="flex h-[120px] w-full flex-col justify-end gap-2 rounded-xl bg-cover p-4"
        >
          <p className="text-sm font-medium">Дневник желаний</p>
          <p className="text-xs text-second">записей: {allWishes.length} </p>
        </SimpleLink>
        <SimpleLink
          to={allFears.length > 0 ? 'fear/list' : 'fear'}
          style={{ backgroundImage: `url('images/notebooks/2.svg')` }}
          className="flex h-[120px] w-full flex-col justify-end gap-2 rounded-xl bg-cover p-4"
        >
          <p className="text-sm font-medium">Дневник страхов</p>
          <p className="text-xs text-second">записей: {allFears.length}</p>
        </SimpleLink>
        <SimpleLink
          to={allGrates.length > 0 ? 'gratefulness/list' : 'gratefulness'}
          style={{ backgroundImage: `url('images/notebooks/3.svg')` }}
          className="flex h-[120px] w-full flex-col justify-end gap-2 rounded-xl bg-cover p-4"
        >
          <p className="text-sm font-medium">Дневник благодарности</p>
          <p className="text-xs text-second">записей: {allGrates.length}</p>
        </SimpleLink>
      </div>
    </>
  );
};
