import { Link, LinkProps, useResolvedPath } from 'react-router-dom';

export const NavLink = ({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to);
  const { pathname } = window.location;

  const getMatch = () => {
    if (pathname === '/' || resolved.pathname === '/') {
      return resolved.pathname === pathname;
    }

    return pathname.includes(resolved.pathname);
  };

  const match = getMatch();

  return (
    <Link to={to} className={`${match ? 'text-blue' : 'text-second'}`}>
      {children}
    </Link>
  );
};
