import { supabase } from 'lib/supabase';
import { webAppData } from 'lib/tg/get-webapp-data';
import useSWR from 'swr';

export const useGetAccount = () => {
  const getAccount = async () => {
    // todo убрать тестовый айди 1
    const tg_id = webAppData?.initDataUnsafe.user?.id || 857360604;

    let { data: user } = await supabase.from('user').select('*').eq('tg_id', tg_id);

    const foundUser = user?.[0];

    if (!foundUser) {
      throw new Error('User not found');
    }

    localStorage.setItem('tg_id', tg_id.toString());

    return webAppData;
  };

  const createAccount = async () => {
    // todo убрать тестовый айди 1
    const tg_id = webAppData?.initDataUnsafe.user?.id || 857360604;
    const username = `${webAppData.initDataUnsafe.user?.first_name} ${webAppData.initDataUnsafe.user?.last_name}`;

    const { data, error } = await supabase.from('user').insert([{ tg_id, username }]).select();

    if (error) {
      console.log(error);
    }

    localStorage.setItem('tg_id', tg_id.toString());

    return data;
  };

  const { data: account = null } = useSWR('account', async () => {
    try {
      const account = await getAccount();
      return account;
    } catch (e) {
      const response = createAccount();

      return response;
    }
  });

  return { account };
};
