import { SimpleLink } from '../../components/atoms/simple-link';
import { ReactComponent as BookIcon } from 'icons/book.svg';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { ReactComponent as CrossIcon } from 'icons/cross.svg';
import { useState } from 'react';
import { toBeautifulDate } from 'lib/date/to-beautiful-date';
import { supabase } from 'lib/supabase';
import useSWR from 'swr';

type Fear = {
  created_at: string;
  id: number;
  content: string;
  tg_id: string;
  anxiety?: string;
  prevent?: string;
  correct?: string;
  gain?: string;
  inaction?: string;
  is_done: boolean;
};

export const NotebookFearCreate = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<Partial<Fear>>({});
  const [updatingFear, setUpdatingFear] = useState<Fear | null>(null);

  const { data: allItems = [], mutate } = useSWR<Fear[]>('fears', async () => {
    const tg_id = localStorage.getItem('tg_id');
    if (!tg_id) {
      throw new Error('User not found');
    }
    const { data } = await supabase.from('note_fears').select('*').eq('tg_id', Number(tg_id));
    const result = data || [];
    return result.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  });

  const handleToggleDone = async () => {
    if (!updatingFear) {
      return;
    }

    await supabase.from('note_fears').update({ is_done: !updatingFear.is_done }).eq('id', updatingFear.id).select();

    const updatedItems = [...allItems];

    updatedItems[allItems.findIndex((gr) => gr.id === updatingFear.id)] = updatingFear;

    mutate(updatedItems);

    setValue({});
    setOpen(false);
  };

  const handleSave = async (form: Partial<Fear>) => {
    const tg_id = localStorage.getItem('tg_id');
    if (!tg_id) {
      throw new Error('User not found');
    }
    await supabase
      .from('note_fears')
      .insert([{ tg_id, ...form }])
      .select();
    mutate(
      [
        {
          created_at: new Date().toISOString(),
          id: new Date().getTime(),
          tg_id,
          is_done: false,
          ...form,
        } as any,
        ...allItems,
      ].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
    );
  };

  const handleClickSave = async () => {
    if (updatingFear) {
      await supabase
        .from('note_fears')
        .update({ ...value })
        .eq('id', updatingFear.id)
        .select();

      const updatedItems = [...allItems];

      updatedItems[allItems.findIndex((gr) => gr.id === updatingFear.id)] = updatingFear;

      mutate(updatedItems);

      setValue({});
      setOpen(false);
      return;
    }

    handleSave(value);
    setValue({});
    setOpen(false);
    setUpdatingFear(null);
  };

  const handleUpdateFear = (fear: Fear) => () => {
    setUpdatingFear(fear);
    setOpen(true);
    setValue({ ...fear });
  };

  const handleCreate = () => {
    setOpen(true);
    setValue({});
    setUpdatingFear(null);
  };

  const fearsForm = [
    {
      name: 'Переживания',
      question: 'Что плохого может произойти, вытекающее из этого страха?',
      dbKey: 'anxiety',
    },
    {
      name: 'Как предотвратить?',
      question: 'Что сделать, чтобы этого не случилось?',
      dbKey: 'prevent',
    },
    {
      name: 'Как исправить?',
      question: 'Что можно сделать, если переживание произойдет?',
      dbKey: 'correct',
    },
    {
      name: 'Выгоды успеха?',
      question: 'Как изменится твоя жизнь, если ты преодолеешь страх?',
      dbKey: 'gain',
    },
    {
      name: 'Цена бездействия?',
      question: 'Что произойдет, если ты оставишь все, как есть?',
      dbKey: 'inaction',
    },
  ];

  const handleClickDelete = async () => {
    if (!updatingFear) {
      throw new Error('Something went wrong');
    }
    const { error } = await supabase.from('note_fears').delete().eq('id', updatingFear.id);
    setOpen(false);
    setValue({});
    if (error) {
      throw Error('Something went wrong');
    }

    mutate([...allItems.filter((w) => w.id !== updatingFear.id)]);
  };

  const formatFear = (fear: Fear) => (
    <div
      onClick={handleUpdateFear(fear)}
      className="flex flex-col gap-[10px] rounded-xl bg-gray-second p-[10px] text-sm"
    >
      <div className="flex gap-2">
        <span className="font-medium">{toBeautifulDate(fear.created_at)}</span>

        <span className="text-second">{new Date(fear.created_at).toTimeString().slice(0, 5)}</span>
      </div>

      <div className="bg-warning text-red rounded-lg p-2 font-medium">{fear.content}</div>

      <div className="flex flex-col gap-1 border-b-[1px] border-white pb-[10px]">
        <span className="font-medium">Переживание:</span>
        {fear.anxiety || '-'}
      </div>

      <div className="flex flex-col gap-1 border-b-[1px] border-white pb-[10px]">
        <span className="font-medium">Как предотвратить:</span>
        {fear.prevent || '-'}
      </div>

      <div className="flex flex-col gap-1 border-b-[1px] border-white pb-[10px]">
        <span className="font-medium">Как исправить:</span>
        {fear.correct || '-'}
      </div>

      <div className="flex flex-col gap-1 border-b-[1px] border-white pb-[10px]">
        <span className="font-medium">Выгоды успеха:</span>
        {fear.gain || '-'}
      </div>

      <div className="flex flex-col gap-1 pb-[10px]">
        <span className="font-medium">Цена бездействия:</span>
        {fear.inaction || '-'}
      </div>
    </div>
  );

  const doneFears = allItems.filter((i) => i.is_done);
  const notDoneFears = allItems.filter((i) => !i.is_done);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-3 text-xs">
        <SimpleLink className="text-blue" to="/notebooks">
          /Дневники
        </SimpleLink>
        <span className="text-second">/Дневник страхов</span>
      </div>

      <h1>Дневник страхов</h1>

      {allItems.length === 0 && (
        <>
          <div className="mx-auto my-[calc(50%_-_70px)] flex w-[200px] flex-col items-center gap-2 text-second">
            <BookIcon />
            <p className="text-center">Нажми на +, чтобы добавить запись</p>
          </div>
        </>
      )}

      {notDoneFears.map(formatFear)}

      {doneFears.length > 0 && (
        <>
          <h2 className="text-xl font-medium">Преодаленные</h2>
          <div className="opacity-50">{doneFears.map(formatFear)}</div>
        </>
      )}

      <button
        onClick={handleCreate}
        className="fixed bottom-[88px] right-4 flex h-12 w-12 items-center justify-center rounded-full bg-blue shadow-[8px_11px_20px_0px_#2C4EFE33]"
      >
        <PlusIcon />
      </button>

      <div
        className="fixed left-0 top-0 flex h-full w-full flex-col duration-150 ease-in"
        style={{ top: open ? '0' : '100%', opacity: open ? '1' : '0' }}
      >
        <div className="absolute -top-10 flex h-1/3 min-h-[32px] w-full bg-[#bdbdbd]" />

        <button
          className="absolute right-4 top-[56px] z-10 flex h-6 w-6 items-center justify-center rounded-full bg-bg p-[2px]"
          onClick={() => setOpen(false)}
        >
          <CrossIcon />
        </button>
        <div className="h-8 flex-none" />
        <div className="relative flex h-full flex-col gap-6 overflow-auto rounded-t-[32px] bg-bg p-6 pb-20">
          <h1>Опиши свой страх</h1>

          <textarea
            value={value.content}
            onChange={({ target: { value: content } }) => setValue((val) => ({ ...val, content }))}
            placeholder="Я боюсь, что..."
            className="min-h-[200px] rounded-xl bg-gray-second p-[10px]"
          />

          {fearsForm.map((questionForm) => (
            <div className="flex flex-col gap-1">
              <span className="font-medium">{questionForm.name}</span>
              <span className="text-sm">{questionForm.question}</span>
              <textarea
                value={(value as any)[questionForm.dbKey] || ''}
                onChange={({ target: { value } }) => setValue((val) => ({ ...val, [questionForm.dbKey]: value }))}
                placeholder="..."
                className="mt-2 min-h-[100px] rounded-lg bg-gray-second p-[10px]"
              />
            </div>
          ))}

          <div className="mt-auto flex w-full flex-col gap-2">
            {updatingFear && (
              <>
                {updatingFear.is_done ? (
                  <button
                    onClick={handleToggleDone}
                    className="flex h-12 items-center justify-center gap-[10px] rounded-lg border-[1px] border-blue font-medium text-blue"
                  >
                    Сделать актуальным
                  </button>
                ) : (
                  <button
                    onClick={handleToggleDone}
                    className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-blue font-medium text-white"
                  >
                    Преодалеть страх
                  </button>
                )}
              </>
            )}

            <button
              onClick={handleClickSave}
              className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-blue font-medium text-white"
            >
              Сохранить
            </button>

            {updatingFear && (
              <button
                onClick={handleClickDelete}
                className="bg-warning text-red flex h-12 items-center justify-center gap-[10px] rounded-lg font-medium"
              >
                Удалить
              </button>
            )}

            <SimpleLink to="/notebooks" className="contents">
              <button className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-gray-second font-medium text-black">
                Назад
              </button>
            </SimpleLink>
          </div>
        </div>
      </div>
    </div>
  );
};
