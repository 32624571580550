import { SimpleLink } from '../../components/atoms/simple-link';
import { ReactComponent as GrateIcon } from 'icons/grate.svg';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { ReactComponent as CrossIcon } from 'icons/cross.svg';
import { useState } from 'react';
import { Tumbler, Option } from 'components/molecules/tumbler';
import { supabase } from 'lib/supabase';
import useSWR from 'swr';
import { toBeautifulDate } from 'lib/date/to-beautiful-date';

const options: Option[] = [
  {
    text: 'Себе',
    value: 'self',
    placeholder: 'Я молодец, что...',
  },
  {
    text: 'Другим',
    value: 'other',
    placeholder: 'Я благодарен... за то, что...',
  },
  {
    text: 'Миру',
    value: 'world',
    placeholder: 'Мне приятно, что...',
  },
];

type Grate = {
  created_at: string;
  id: number;
  type: string;
  content: string;
  tg_id: string;
};

export const NotebookGrateCreate = () => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(options[0]);
  const [value, setValue] = useState<string>('');
  const [updatingGrate, setUpdatingGrate] = useState<Grate | null>(null);

  const { data: allItems = [], mutate } = useSWR<Grate[]>('grates', async () => {
    const tg_id = localStorage.getItem('tg_id');
    if (!tg_id) {
      throw new Error('User not found');
    }
    const { data } = await supabase.from('note_grates').select('*').eq('tg_id', Number(tg_id));
    const result = data || [];
    return result.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  });

  const handleSave = async (content: string, type: string) => {
    const tg_id = localStorage.getItem('tg_id');
    if (!tg_id) {
      throw new Error('User not found');
    }
    await supabase.from('note_grates').insert([{ tg_id, content, type }]).select();
    mutate(
      [
        {
          created_at: new Date().toISOString(),
          id: new Date().getTime(),
          type,
          content,
          tg_id,
        },
        ...allItems,
      ].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
    );
  };

  const handleClickSave = async () => {
    if (updatingGrate) {
      await supabase
        .from('note_grates')
        .update({ content: value, type: active.text })
        .eq('id', updatingGrate.id)
        .select();

      const updatedItems = [...allItems];

      updatedItems[allItems.findIndex((gr) => gr.id === updatingGrate.id)] = updatingGrate;

      mutate(updatedItems);

      setValue('');
      setOpen(false);
      return;
    }

    handleSave(value, active.text);
    setValue('');
    setOpen(false);
  };

  const groupGrates = (grates: Grate[]) => {
    return grates.reduce(
      (acc, grate) => {
        const currentMonth = new Date(grate.created_at).toISOString().slice(0, 7);
        return { ...acc, [currentMonth]: [...(acc[currentMonth] || []), grate] };
      },
      {} as Record<string, Grate[]>,
    );
  };

  const getDateTag = (dateString: string) => {
    const date = new Date(dateString);
    const shortTag = date.toLocaleString('ru', { month: 'long' });

    if (date.getFullYear() === new Date().getFullYear()) {
      return shortTag;
    }
    return shortTag + ' ' + date.getFullYear();
  };

  const handleClickDelete = async () => {
    if (!updatingGrate) {
      throw new Error('Something went wrong');
    }
    const { error } = await supabase.from('note_grates').delete().eq('id', updatingGrate.id);
    setOpen(false);
    setValue('');
    if (error) {
      throw Error('Something went wrong');
    }

    mutate([...allItems.filter((w) => w.id !== updatingGrate.id)]);
  };

  const handleUpdateGrate = (grate: Grate) => () => {
    setUpdatingGrate(grate);
    setOpen(true);
    setActive(options.find((opt) => opt.text === grate.type) || options[0]);
    setValue(grate.content);
  };

  const handleCreate = () => {
    setOpen(true);
    setValue('');
    setUpdatingGrate(null);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-3 text-xs">
        <SimpleLink className="text-blue" to="/notebooks">
          /Дневники
        </SimpleLink>
        <span className="text-second">/Дневник благодарности</span>
      </div>

      <h1>Благодарности</h1>

      {allItems.length === 0 && (
        <>
          <p>
            По результатам исследований, чем чаще человек благодарит себя, мир и окружающих, тем счастливее он себя
            чувствует и мотивируется на новые достижения.
          </p>

          <div className="mx-auto my-[calc(50%_-_70px)] flex w-[200px] flex-col items-center gap-2 text-second">
            <GrateIcon />
            <p className="text-center">Нажми на +, чтобы добавить благодарность</p>
          </div>
        </>
      )}

      {allItems.length > 0 && (
        <div className="flex flex-col gap-6">
          {Object.entries(groupGrates(allItems)).map(([tagDateString, values]) => (
            <div className="flex flex-col gap-3" key={tagDateString}>
              <div className="w-fit rounded-lg border-[1px] border-blue px-2 py-1 text-sm capitalize text-blue">
                {getDateTag(tagDateString)}
              </div>

              {values.map((grate) => (
                <div
                  onClick={handleUpdateGrate(grate)}
                  className="flex flex-col gap-3 rounded-xl bg-gray-second p-[10px] text-sm"
                >
                  <div className="flex items-center justify-between">
                    <span className="font-medium">{grate.type}:</span>
                    <span className="text-second">{toBeautifulDate(grate.created_at)}</span>
                  </div>
                  {grate.content}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      <button
        onClick={handleCreate}
        className="fixed bottom-[88px] right-4 flex h-12 w-12 items-center justify-center rounded-full bg-blue shadow-[8px_11px_20px_0px_#2C4EFE33]"
      >
        <PlusIcon />
      </button>

      <div
        className="fixed left-0 top-0 flex h-full w-full flex-col duration-150 ease-in"
        style={{ top: open ? '0' : '100%', opacity: open ? '1' : '0' }}
      >
        <div className="absolute -top-10 flex h-1/3 min-h-[32px] w-full bg-[#bdbdbd]" />

        <button
          className="absolute right-4 top-[56px] z-10 flex h-6 w-6 items-center justify-center rounded-full bg-bg p-[2px]"
          onClick={() => setOpen(false)}
        >
          <CrossIcon />
        </button>
        <div className="h-8 flex-none" />
        <div className="relative flex h-full flex-col gap-6 overflow-auto rounded-t-[32px] bg-bg p-6 pb-20">
          <h1>Благодарности</h1>

          <Tumbler active={active} setActive={setActive} options={options} />

          <textarea
            value={value}
            onChange={({ target: { value } }) => setValue(value)}
            placeholder={active.placeholder}
            className="min-h-[200px] rounded-xl bg-gray-second p-[10px]"
          />

          <div className="mt-auto flex w-full flex-col gap-2">
            <button
              onClick={handleClickSave}
              className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-blue font-medium text-white"
            >
              Сохранить
            </button>

            {updatingGrate && (
              <button
                onClick={handleClickDelete}
                className="bg-warning text-red flex h-12 items-center justify-center gap-[10px] rounded-lg font-medium"
              >
                Удалить
              </button>
            )}

            <SimpleLink to="/notebooks" className="contents">
              <button className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-gray-second font-medium text-black">
                Назад
              </button>
            </SimpleLink>
          </div>
        </div>
      </div>
    </div>
  );
};
