export const exercises = [
  {
    name: 'Инвентаризация',
    content: `Оглянитесь вокруг и внимательно осмотрите помещение, в котором вы находитесь. Обращайте внимание на мельчайшие детали, даже если вы их хорошо знаете. Медленно, не торопясь, мысленно переберите все предметы один за другим в определённой последовательности. Постарайтесь полностью сосредоточиться на этой “инвентаризации”. Говорите мысленно самому себе ”коричневый письменный стол, белые занавески, красная ваза для цветов” и т. д. 

Сосредоточившись на каждом отдельном предмете, вы отвлечётесь от внутреннего стрессового напряжения, направляя своё внимание на рациональное восприятие окружающей обстановки.`,
    image: 'jamdani.svg',
    background: 'linear-gradient(180deg, #D9D8FF 0%, #FFFFFF 100%)',
  },
  {
    name: 'Смена обстановки',
    content: `Если позволяют обстоятельства, покиньте помещение, в котором у вас возник острый стресс. Перейдите в другое, где никого нет, или выйдите на улицу, где сможете остаться наедине со своими мыслями. Разберите мысленно это помещение (если вы вышли на улицу, то окружающие дома, природу) по составляющим, как в способе "Инвентаризация".`,
    image: 'ruti.svg',
    background: '#ECFFC4',
  },
  {
    name: 'Сундук',
    content: `Представьте, что внутри Вас, в области солнечного сплетения, находится большой сундук. Мысленно сложите в него все свои обиды, тревоги, печали. Резко выдохните, представляя, что Ваш сундук отправляется в межгалактический полет к звездам. Можете даже дать ему какое-то мысленное напутствие, вроде «в черную дыру, на переработку». Выдыхайте столько раз, сколько требуется, чтобы Вы полностью очистились от негатива.`,
    image: 'comola.svg',
    background: '#FCDEFF',
  },
  {
    name: 'Ну и что',
    content: `Возьмите лист бумаги и ручку. Напишите самую тревожную Вашу проблему. Допустим, Вы плохо выполнили отчет на работе. После чего задайте себе вопрос «Ну и что?». Запишите первый ответ, который пришел в голову, например «меня уволят с работы». Повторно задайте себе тот же вопрос «Ну и что?», и запишите новый ответ, например «я останусь без денег». Продолжайте до тех пор, пока Ваши страхи не будут полностью выписаны. К концу упражнения Вы почувствуете легкость, веселье, прежняя проблема не будет уже казаться такой ужасной.`,
    image: 'rosh.svg',
    background: '#FFF9C5',
  },
  {
    name: 'Воздушный шар',
    content: `Представьте себе, что Вы – большой воздушный шар, который медленно надувают. На длинном, глубоком вдохе поднимите руки, и полностью напрягите все мышцы тела на несколько секунд. Затем представьте, что в шаре образовалась маленькая дырочка, из которой постепенно выходит воздух. На выдохе, начните постепенно «сдуваться», понемногу расслабляя все мышцы. Заканчиваем упражнение состоянием полного расслабления.`,
    image: 'poro.svg',
    background: '#D4FFFF',
  },
  {
    name: 'Свободное письмо',
    content: `Эта техника подразумевает безостановочное и безоценочное письмо в течение определенного времени (например, 5-10 минут). При этом не стоит следить за красотой речи, пунктуациями и грамматикой. Суть фрирайтинга — «слить» напряжение и стресс на бумагу, не зря писательница Джулия Кэмерон назвала такой формат письма «канализацией для мозгов».`,
    image: 'murg.svg',
    background: '#FFE4C5',
  },
  {
    name: 'Тряска',
    content: `Эта телесная техника помогает запустить работу парасимпатической системы, высвободить избыток гормонов стресса кортизола и адреналина и снять напряжение. Перед выполнением упражнения закройте дверь, чтобы никто вас не видел. Встаньте на пол босиком или в удобной обуви. Начните трясти кистями рук, постепенно включая в работу локти, плечи, корпус, голову, ноги. Можно немного потопать ногами по полу. Тряска должна быть интенсивной, чтобы «включилось» все тело.`,
    image: 'shut.svg',
    background: '#FFC8C8',
  },
  {
    name: 'Пять чувств',
    content: `Это очень простое и эффективное упражнение для переключения из одного эмоционального состояния на другое. Оно поможет вернуться в «здесь и сейчас» и снизит стресс. В момент, когда чувствуете напряжение, беспокойство или гнев, отметьте вокруг себя:

- пять предметов, которые вы можете рассмотреть;
- четыре звука, которые вы слышите;
- три предмета с разной фактурой, к которым можно прикоснуться;
- два запаха, которые вы можете ощущать;
- один вкус, который вы можете попробовать.`,
    image: 'chot.svg',
    background: '#CAFFD6',
  },
  {
    name: 'Превратите в якорь слово',
    content: `Еще одним якорем, который будет связывать вас с настоящим моментом, может стать слово или целая фраза. Например, союз «и». Ищите его, пока едете на работу и слушаете песни или сводку новостей; когда находитесь в переполненном ресторане или едете на автобусе и слышите разговоры вокруг. Если идете на работу пешком, ищите «и» на вывесках и афишах. Этот простой прием вернет вас к настоящему.

В течение нескольких дней используйте «и» как якорь. После чего опишите способы, с помощью которых вам это удалось. Что получилось легко, а что оказалось трудным? Почему?`,
    image: 'hali.svg',
    background: '#D5EBFF',
  },
  {
    name: 'Корабль',
    content: `Схема: представьте, что вы большой корабль, который отчаливает от берега. С каждым шагом ветер все больше наполняет ваши паруса, и вы плавно движетесь в открытое море. Каждый следующий шаг все сильнее рассекает волны, и вы точно движетесь к своей цели.

Упражнение следует выполнять, когда цель вашей прогулки конечна (например: дойти до дома или до места учебы)`,
    image: 'shap.svg',
    background: '#ECD8FF',
  },
];
