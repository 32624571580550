import { ReactComponent as NoteIcon } from '../icons/note.svg';
import { ReactComponent as CalendarIcon } from '../icons/calendar.svg';
import { ReactComponent as ClipboardIcon } from '../icons/clipboard.svg';
import { ReactComponent as HeartIcon } from '../icons/heart.svg';
import { ReactComponent as MessageIcon } from '../icons/message.svg';
import { BreatheExercises } from '../templates/breathe-exercises';
import { Notebooks } from '../templates/notebooks';
import { NotebookWishes } from '../templates/notebook-wishes';
import { NotebookFears } from '../templates/notebook-fears';
import { BasicExercises } from '../templates/basic-exercises';
import { createRef } from 'react';
import { NotebookGratefulness } from '../templates/notebook-gratefulness';
import { NotebookWishesCreate } from '../templates/notebook-wishes-create';
import { NotebookGrateCreate } from 'templates/notebook-grate-create';
import { NotebookFearCreate } from 'templates/notebook-fear-create';

export const routes = [
  { path: '/', name: <NoteIcon />, element: <BasicExercises />, nodeRef: createRef() },
  { path: '/breath-exercises', name: <HeartIcon />, element: <BreatheExercises />, nodeRef: createRef() },
  { path: '/notebooks', name: <MessageIcon />, element: <Notebooks />, nodeRef: createRef() },
  { path: '/notebooks/wishes', name: null, element: <NotebookWishes />, nodeRef: createRef() },
  { path: '/notebooks/wishes/list', name: null, element: <NotebookWishesCreate />, nodeRef: createRef() },
  { path: '/notebooks/fear', name: null, element: <NotebookFears />, nodeRef: createRef() },
  { path: '/notebooks/fear/list', name: null, element: <NotebookFearCreate />, nodeRef: createRef() },
  { path: '/notebooks/gratefulness', name: null, element: <NotebookGratefulness />, nodeRef: createRef() },
  { path: '/notebooks/gratefulness/list', name: null, element: <NotebookGrateCreate />, nodeRef: createRef() },
  { path: '/clipboard', name: <ClipboardIcon />, element: null, nodeRef: createRef() },
  { path: '/stats', name: <CalendarIcon />, element: null, nodeRef: createRef() },
];

export const handleClickNav = (currentPath: string, path: string) => () => {
  const clickedPathIndex = routes.findIndex((r) => r.path === path);
  const currentIndex = routes.findIndex((r) => r.path === currentPath);

  if (clickedPathIndex > currentIndex) {
    document.body.classList.add('right');
  } else {
    document.body.classList.remove('right');
  }
};
