import './index.css';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider, useLocation, useOutlet } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { NavLink } from './components/atoms/navlink';
import { handleClickNav, routes } from './utils/routes';
import { useEffect } from 'react';
import { useGetAccount } from 'lib/hooks/use-get-account';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
    })),
  },
]);

function App() {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const { nodeRef } = routes.find((route) => route.path === location.pathname) ?? {};

  useEffect(() => {
    window.Telegram.WebApp?.expand();
  }, []);

  const { account } = useGetAccount();

  console.log({ account });

  return (
    <div>
      <SwitchTransition>
        <CSSTransition key={location.pathname} nodeRef={nodeRef as any} timeout={180} classNames="page" unmountOnExit>
          {() => {
            return (
              <div ref={nodeRef as any} className="flex h-full flex-col gap-3 px-4 pb-6 pt-8">
                {currentOutlet}
              </div>
            );
          }}
        </CSSTransition>
      </SwitchTransition>
      <div className="relative -z-10 h-16" />
      <ul className="fixed bottom-0 z-10 flex w-full justify-between bg-bg px-4 py-5 shadow-normal">
        {routes
          .filter((r) => r.name)
          .map((route) => (
            <li key={route.path} onClick={handleClickNav(location.pathname, route.path)}>
              <NavLink to={route.path}>{route.name}</NavLink>
            </li>
          ))}
      </ul>
    </div>
  );
}

const container = document.getElementById('root');
const root = createRoot(container as any);
root.render(<RouterProvider router={router} />);
