import { Exercise } from '../../components/molecules/exercise';
import { exercises } from './constants';

export const BreatheExercises = () => {
  return (
    <>
      <h1>Дыхательные практики</h1>
      <div className="grid grid-cols-2 gap-3">
        {exercises.map((exercise) => (
          <Exercise {...exercise} key={exercise.name} />
        ))}
      </div>
    </>
  );
};
