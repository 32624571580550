import { ReactComponent as Icon } from '../../icons/fear-icon.svg';
import { ReactComponent as Arrow } from '../../icons/arrow.svg';
import { SimpleLink } from 'components/atoms/simple-link';

export const NotebookFears = () => {
  return (
    <div className="flex h-[calc(100vh_-_96px)] flex-col justify-center gap-10">
      <div className="flex flex-col items-center gap-4">
        <Icon />
        <h1>Дневник страхов</h1>
        <p className="text-center">
          поможет преодолеть твои страхи, основываясь на выгодах, которые ты получишь в результате действий
        </p>
      </div>
      <div className="flex w-full flex-col gap-2">
        <SimpleLink to="list" className="contents">
          <button className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-blue font-medium text-white">
            Описать страх
            <Arrow />
          </button>
        </SimpleLink>

        <SimpleLink to="/notebooks" className="contents">
          <button className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-gray-second font-medium text-black">
            К другим дневникам
          </button>
        </SimpleLink>
      </div>
    </div>
  );
};
