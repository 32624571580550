import { ReactComponent as WishesIcon } from 'icons/wishes-icon.svg';
import { ReactComponent as Arrow } from 'icons/arrow.svg';
import { SimpleLink } from 'components/atoms/simple-link';

export const NotebookGratefulness = () => {
  return (
    <div className="flex h-[calc(100vh_-_96px)] flex-col justify-center gap-10">
      <div className="flex flex-col items-center gap-4">
        <WishesIcon />
        <h1>Дневник благодарности</h1>
        <p className="text-center">
          концентрирует на хорошем и усиливает радость от жизни, когда ты благодаришь себя, мир и других
        </p>
      </div>
      <div className="flex w-full flex-col gap-2">
        <SimpleLink to="list" className="contents">
          <button className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-blue font-medium text-white">
            Поблагодарить
            <Arrow />
          </button>
        </SimpleLink>

        <SimpleLink to="/notebooks" className="contents">
          <button className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-gray-second font-medium text-black">
            К другим дневникам
          </button>
        </SimpleLink>
      </div>
    </div>
  );
};
