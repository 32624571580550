import { SimpleLink } from '../../components/atoms/simple-link';
import { ReactComponent as StarIcon } from 'icons/star-wish.svg';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { ReactComponent as CrossIcon } from 'icons/cross.svg';
import { ReactComponent as CheckboxIcon } from 'icons/checkbox.svg';
import { useState } from 'react';
import useSWR from 'swr';
import { supabase } from 'lib/supabase';
import { toBeautifulDate } from 'lib/date/to-beautiful-date';

type Wish = {
  created_at: string;
  id: number;
  is_done: boolean;
  name: string;
  tg_id: string;
};

export const NotebookWishesCreate = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>('');

  const [initAnimation, setInitAnimation] = useState(0);

  const { data: allWishes = [], mutate } = useSWR<Wish[]>('wishes', async () => {
    const tg_id = localStorage.getItem('tg_id');
    if (!tg_id) {
      throw new Error('User not found');
    }
    const { data } = await supabase.from('note_wishes').select('*').eq('tg_id', Number(tg_id));
    const result = data || [];
    return result.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
  });

  const handleSave = async (name: string) => {
    const tg_id = localStorage.getItem('tg_id');
    if (!tg_id) {
      throw new Error('User not found');
    }
    await supabase.from('note_wishes').insert([{ tg_id, name }]).select();
    mutate([
      ...allWishes,
      {
        created_at: new Date().toISOString(),
        id: new Date().getTime(),
        is_done: false,
        name,
        tg_id,
      },
    ]);
  };

  const handleClickSave = () => {
    handleSave(value);
    setValue('');
    setOpen(false);
  };

  const toggleDone = (wish: Wish) => async () => {
    setInitAnimation(wish.id);

    await Promise.all([
      supabase.from('note_wishes').update({ is_done: !wish.is_done }).eq('id', wish.id).select(),
      new Promise((resolve) => setTimeout(resolve, 300)),
    ]);

    const currentWishId = allWishes.findIndex((w) => w.id === wish.id);

    const newWishesList = [...allWishes];
    newWishesList[currentWishId].is_done = !wish.is_done;
    mutate(newWishesList, { revalidate: false });
    setInitAnimation(0);
  };

  const deleteWish = (id: number) => async () => {
    const { error } = await supabase.from('note_wishes').delete().eq('id', id);

    if (error) {
      throw Error('Something went wrong');
    }

    mutate([...allWishes.filter((w) => w.id !== id)]);
  };

  const doneWishes = allWishes.filter((w) => w.is_done);
  const currWishes = allWishes.filter((w) => !w.is_done);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-3 text-xs">
        <SimpleLink className="text-blue" to="/notebooks">
          /Дневники
        </SimpleLink>
        <span className="text-second">/Дневник желаний</span>
      </div>

      <h1>Дневник желаний</h1>

      {allWishes.length > 0 ? (
        <div className="flex flex-col gap-6">
          {currWishes.map((wish, index, array) => (
            <div
              style={{
                position: 'relative',
                top: initAnimation === wish.id ? 88 * (array.length - index) : 0,
                opacity: initAnimation === wish.id ? 0 : 1,
              }}
              key={wish.id}
              className={`relative flex items-center justify-between gap-2 duration-300`}
            >
              <span className="absolute right-6 top-[-16px] ml-auto text-xs text-second">
                {toBeautifulDate(wish.created_at)}
              </span>
              <button
                onClick={toggleDone(wish)}
                className="flex w-full items-center gap-3 rounded-xl bg-gray-second px-3 py-3 text-sm"
              >
                <div>
                  <CheckboxIcon className={'text-gray opacity-15'} />
                </div>

                <span>{wish.name}</span>
              </button>

              <button onClick={deleteWish(wish.id)}>
                <CrossIcon className="h-4 w-4" />
              </button>
            </div>
          ))}

          {doneWishes.length > 0 && <h2 className="mt-3 text-xl font-medium">Выполненные</h2>}

          {doneWishes.map((wish, index, array) => (
            <div
              style={{
                position: 'relative',
                bottom: initAnimation === wish.id ? 88 * (array.length - index) : 0,
                opacity: initAnimation === wish.id ? 0 : 1,
              }}
              key={wish.id}
              className={`relative flex items-center justify-between gap-2 duration-300`}
            >
              <span className="absolute right-6 top-[-16px] ml-auto text-xs text-second">
                {toBeautifulDate(wish.created_at)}
              </span>
              <button
                onClick={toggleDone(wish)}
                className="flex w-full items-center gap-3 rounded-xl bg-gray-second px-[10px] py-[14px] text-sm"
              >
                <div>
                  <CheckboxIcon className={'text-blue'} />
                </div>

                <span className={'line-through opacity-55'}>{wish.name}</span>
              </button>

              <button onClick={deleteWish(wish.id)}>
                <CrossIcon className="h-4 w-4" />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <>
          <p>Чтобы научиться мечтать, начни с добавления 10 желаний.</p>

          <div className="mx-auto my-[calc(50%_-_70px)] flex w-[200px] flex-col items-center gap-2 text-second">
            <StarIcon />
            <p className="text-center">Нажми на +, чтобы добавить желание</p>
          </div>
        </>
      )}

      <button
        onClick={() => setOpen(true)}
        className="fixed bottom-[88px] right-4 flex h-12 w-12 items-center justify-center rounded-full bg-blue shadow-[8px_11px_20px_0px_#2C4EFE33]"
      >
        <PlusIcon />
      </button>

      <div
        className="fixed left-0 top-0 flex h-full w-full flex-col duration-150 ease-in"
        style={{ top: open ? '0' : '100%', opacity: open ? '1' : '0' }}
      >
        <div className="absolute -top-10 flex h-1/3 min-h-[32px] w-full bg-[#bdbdbd]" />

        <button
          className="absolute right-4 top-[56px] z-10 flex h-6 w-6 items-center justify-center rounded-full bg-bg p-[2px]"
          onClick={() => setOpen(false)}
        >
          <CrossIcon />
        </button>
        <div className="h-8 flex-none" />
        <div className="relative flex h-full flex-col gap-6 overflow-auto rounded-t-[32px] bg-bg p-6 pb-20">
          <h1>Добавить желание</h1>
          <textarea
            value={value}
            onChange={({ target: { value } }) => setValue(value)}
            placeholder={'Я хочу...'}
            className="min-h-[200px] rounded-xl bg-gray-second p-[10px]"
          />

          <div className="mt-auto flex w-full flex-col gap-2">
            <button
              onClick={handleClickSave}
              className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-blue font-medium text-white"
            >
              Сохранить
            </button>

            <SimpleLink to="/notebooks" className="contents">
              <button className="flex h-12 items-center justify-center gap-[10px] rounded-lg bg-gray-second font-medium text-black">
                Назад
              </button>
            </SimpleLink>
          </div>
        </div>
      </div>
    </div>
  );
};
