import { SimpleLink } from '../../components/atoms/simple-link';

export const Notebooks = () => {
  return (
    <>
      <h1>Дневники</h1>
      <p className="mt-1">
        Используя дневники, ты можешь запечатлеть текущий жизненный процесс, события, идеи и переживания. Это письмо "из
        сейчас о сейчас". Здесь ты можешь выговориться и понять себя.
      </p>
      <div className="mt-3 flex flex-col gap-3">
        <SimpleLink
          to="wishes"
          style={{ backgroundImage: `url('images/notebooks/1.svg')` }}
          className="flex h-[120px] w-full flex-col justify-end gap-2 rounded-xl bg-cover p-4"
        >
          <p className="text-sm font-medium">Дневник желаний</p>
          <p className="text-xs text-second">1 запись</p>
        </SimpleLink>
        <SimpleLink
          to="fear"
          style={{ backgroundImage: `url('images/notebooks/2.svg')` }}
          className="flex h-[120px] w-full flex-col justify-end gap-2 rounded-xl bg-cover p-4"
        >
          <p className="text-sm font-medium">Дневник страхов</p>
          <p className="text-xs text-second">2 записи</p>
        </SimpleLink>
        <SimpleLink
          to="gratefulness"
          style={{ backgroundImage: `url('images/notebooks/3.svg')` }}
          className="flex h-[120px] w-full flex-col justify-end gap-2 rounded-xl bg-cover p-4"
        >
          <p className="text-sm font-medium">Дневник благодарности</p>
          <p className="text-xs text-second">5 записей</p>
        </SimpleLink>
      </div>
    </>
  );
};
